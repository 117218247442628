import React, { useContext, useMemo } from 'react';

import useInterval from 'ecto-common/lib/hooks/useInterval';
import _ from 'lodash';

import { useSignalSeriesName } from 'ecto-common/lib/Dashboard/datasources/signalUtils';
import { numDecimalsForUnit } from 'ecto-common/lib/Charts/UnitUtil';
import {
  getAlternativeColor,
  shadeColor
} from 'ecto-common/lib/SignalSelector/StockChart.config';
import moment from 'moment/moment';
import {
  GetEnumsAndFixedConfigurationsResponseModel,
  SamplingInterval,
  SignalTypeResponseModel
} from 'ecto-common/lib/API/APIGen';
import { TimeRangeOptions } from 'ecto-common/lib/types/TimeRangeOptions';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import T from 'ecto-common/lib/lang/Language';
import animations from 'ecto-common/lib/styles/variables/animations';
import StockChart from 'ecto-common/lib/Charts/StockChart';
import ErrorBoundary from 'ecto-common/lib/utils/ErrorBoundary';
import PanelDataType from 'ecto-common/lib/Dashboard/panels/PanelDataType';
import { Highcharts } from 'ecto-common/lib/Highcharts/Highcharts';
import {
  MatchedSignalInput,
  getSignalAggregationModels,
  SignalValuesDataSourceResult
} from 'ecto-common/lib/Dashboard/datasources/SignalValuesDataSource';
import { migrateSignalSettingSystemNamesToSignalTypes } from 'ecto-common/lib/Dashboard/migrations/datasourceUtil';
import {
  getSignalTypeUnit,
  getSignalTypeUnitObject
} from 'ecto-common/lib/SignalSelector/SignalUtils';
import HelpPaths from 'ecto-common/help/tocKeys';
import DashboardDataContext from 'ecto-common/lib/hooks/DashboardDataContext';
import {
  TelemetrySeries,
  createPointFormatter,
  getMinOrMaxForAxis,
  yAxisFormatter
} from 'ecto-common/lib/SignalSelector/ChartUtils';
import {
  CustomPanelProps,
  DashboardPanel,
  PanelSizeType
} from 'ecto-common/lib/Dashboard/Panel';
import DataSourceTypes from 'ecto-common/lib/Dashboard/datasources/DataSourceTypes';
import { GraphMinMaxSettings } from 'ecto-common/lib/types/EctoCommonTypes';
import { graphMinMaxSection } from 'ecto-common/lib/Dashboard/util/GraphMinMaxModelEditor';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';

const formatDate = (date: moment.MomentInput) =>
  moment(date).format('YYYY-MM-DD HH:mm');
const formatDayFull = (date: moment.MomentInput) =>
  moment(date).format('YYYY-MM-DD');
const formatDayName = (date: moment.MomentInput) =>
  Highcharts.getOptions().lang.weekdays[moment(date).day()];
const formatHour = (date: moment.MomentInput) => moment(date).format('HH:mm');
const formatWeek = (date: moment.MomentInput) => moment(date).format('W');
const formatWeekFull = (date: moment.MomentInput) =>
  moment(date).format('YYYY W');
const formatYear = (date: moment.MomentInput) => moment(date).format('YYYY');
// moment did not have working localization, so we use highcharts
const formatMonth = (date: moment.MomentInput) =>
  Highcharts.getOptions().lang.shortMonths[moment(date).month()];
const formatMonthFull = (date: moment.MomentInput) =>
  moment(date).format('YYYY-MM');

const DEFAULT_REFRESH_INTERVAL = 15;
const MIN_REFRESH_INTERVAL = 15;

enum ChartStackingOption {
  DISABLED = 'disabled', // TODO: Better solution, can't be undefined, check in actual config code to set to undefined there instead
  NORMAL = 'normal',
  PERCENT = 'percent'
}

const ChartStackingOptionsText = Object.freeze({
  [ChartStackingOption.DISABLED]:
    T.admin.dashboards.panels.types.barchart.stackingoption.disabled,
  [ChartStackingOption.NORMAL]:
    T.admin.dashboards.panels.types.barchart.stackingoption.normal,
  [ChartStackingOption.PERCENT]:
    T.admin.dashboards.panels.types.barchart.stackingoption.percent
});

const DEFAULT_STACKING_OPTION = ChartStackingOption.NORMAL;
const formattersFromSamplingInterval = (samplingInterval: SamplingInterval) => {
  switch (samplingInterval) {
    case SamplingInterval.Day:
      return [formatDayFull, formatDayFull];
    case SamplingInterval.Week:
      return [formatWeek, formatWeekFull];
    case SamplingInterval.Month:
      return [formatMonth, formatMonthFull];
    case SamplingInterval.Raw:
    case SamplingInterval.Hour:
    case SamplingInterval.Minute:
    default:
      return [formatDate, formatDate];
  }
};

const formattersFromTimeRange = (timeRange: TimeRangeOptions) => {
  switch (timeRange) {
    case TimeRangeOptions.DAY:
      return [formatHour, formatDate];
    case TimeRangeOptions.WEEK:
      return [formatDayName, formatDayFull];
    case TimeRangeOptions.MONTH:
      return [formatDayFull, formatDayFull];
    case TimeRangeOptions.YEAR:
      return [formatMonth, formatMonthFull];
    case TimeRangeOptions.FIVE_YEARS_BACK:
      return [formatYear, formatYear];
    default:
      return [formatDate, formatDate];
  }
};

type BarChartPanelConfig = {
  refreshInterval?: number;
  samplingInterval?: SamplingInterval;
  stacking?: ChartStackingOption;
  hasPointsOverflow?: boolean;
  hideLegend?: boolean;
  size?: PanelSizeType;
  xAxisStep?: number;
  minMaxSettings?: Record<string, GraphMinMaxSettings>;
};

type BarChartPanelContentProps = SignalValuesDataSourceResult & {
  onReload(): void;
} & BarChartPanelConfig;

type DataConfig = {
  yAxis: Highcharts.YAxisOptions[];
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  series: any[];
  categories: string[];
  seriesIds: string[];
};

const BarChartPanelContent = ({
  signalValues,
  signalInfo,
  isLoading,
  hasError,
  onReload,
  refreshInterval = DEFAULT_REFRESH_INTERVAL,
  stacking = DEFAULT_STACKING_OPTION,
  samplingInterval,
  hasPointsOverflow,
  size,
  hideLegend,
  minMaxSettings,
  xAxisStep = undefined
}: BarChartPanelContentProps) => {
  const getSignalSeriesName = useSignalSeriesName();
  const { signalTypesMap, signalUnitTypesMap } =
    useContext(DashboardDataContext);

  // Convert signalValues to
  // {
  //  categories: [ Unique set of time values merged from all signals ]
  //  series: [ [ Array of each signal values ] ]
  // }
  const dataConfig: DataConfig = useMemo(() => {
    // Create a new signal setting that also keep count of number of usages of the default setting
    // that way we can create a new shade of colors that are used more than once
    // Ex: color1.usage: 3 => shade color 1 in 3 different ways
    const usageCount: Record<string, number> = {};
    const timerange = signalInfo?.timeRange;
    const seriesIds: string[] = [];

    const yAxisIds = _.uniq(
      signalValues.map(
        (x) =>
          getSignalTypeUnitObject(
            signalInfo.signals[x.signalId].signalTypeId,
            signalTypesMap,
            signalUnitTypesMap
          ).id
      )
    );

    const yAxis = yAxisIds.map((id, index) => {
      const item = signalUnitTypesMap[id];
      return {
        id: item.unit,
        title: {
          text: item.unit
        },
        crosshair: false,
        labels: {
          formatter: yAxisFormatter
        },
        opposite: index % 2 === 1,
        min: getMinOrMaxForAxis(item.id, 'min', minMaxSettings),
        max: getMinOrMaxForAxis(item.id, 'max', minMaxSettings)
      };
    });

    // Key: Full date format, value: basic date format. Keeps track of
    // all categories that are going to be shown.
    const categories: Record<string, string> = {};

    const getSignalFormatters = (signalSetting: MatchedSignalInput) => {
      if (
        signalSetting?.samplingInterval != null &&
        signalSetting.timeRange == null
      ) {
        return formattersFromSamplingInterval(
          signalSetting?.samplingInterval ?? samplingInterval
        );
      }

      return formattersFromTimeRange(timerange);
    };

    // Collection of category (full date format) to value mappings. One per
    // signal.
    const valueMaps: Map<string, [string, number]>[] = [];

    // In order to handle result with different length (where some values might be missing), first find
    // all of the unique data points (categories) that will be shown in the bar graph. Store these in the
    // categories set. Also, save every value for each series in a lookup table.
    for (const signalValue of signalValues) {
      const signalSetting = signalValue.signalInput;
      const [basicFormatter, fullFormatter] =
        getSignalFormatters(signalSetting);
      const valueMap = new Map<string, [string, number]>();

      for (const signalTelemetry of signalValue.signals) {
        const { time, value } = signalTelemetry;
        // Some categories like "April" might appear multiple times (2021 and 2022 for instance).
        // We don't want those values to end up in the same category, so we create the
        // categories using a fuller format (like 2022-04) here. Then, at the final step
        // we replace the full category name with the basic name, meaning that we will
        // up with two Aprils but at the correct place, a year apart.
        const fullTimeFormat = fullFormatter(time);
        categories[fullTimeFormat] = basicFormatter(time);
        valueMap.set(fullTimeFormat, [fullTimeFormat, value]);
      }

      valueMaps.push(valueMap);
    }

    const sortedCategories: string[] = _.orderBy([...Object.keys(categories)]);

    // TODO: Unfortunately Highcharts data type definition is incorrect, it doesnt accept all data formats it should. Use this for now.
    const series: TelemetrySeries[] = [];

    // Then, go through each series, and make sure all series that are created have the same length. Do this by
    // using the sortedCategories array instead of iterating over the raw values in the telemetry.
    // If there is a missing value for a given category, use null instead of skipping it. Use the
    // lookup table to find the value (if any) efficiently.

    for (let i = 0; i < signalValues.length; i++) {
      const signalValue = signalValues[i];
      const signalSetting = signalValue.signalInput;

      const valueMap = valueMaps[i];
      const values: [string, number][] = [];

      for (const category of sortedCategories) {
        let value: [string, number];
        if (valueMap.has(category)) {
          value = valueMap.get(category);
        } else {
          value = [category, null];
        }

        values.push([
          value[0],
          signalSetting.rounding != null
            ? _.round(value[1], signalSetting.rounding)
            : value[1]
        ]);
      }

      const signal = signalInfo.signals[signalValue.signalId];
      if (signal) {
        const signalProvider =
          signalInfo.signalProviders[
            signalInfo.signalIdToProviderId[signalValue.signalId]
          ];
        const name = getSignalSeriesName(signal, signalProvider);
        const unit = getSignalTypeUnit(
          signal.signalTypeId,
          signalTypesMap,
          signalUnitTypesMap
        );

        // Not optimal to use this ID, but the best we got. Need to identify uniquely in order for visibility settings to work
        const chartSignalId =
          signalValue.signalId +
          '-' +
          signalSetting?.id +
          '-' +
          signalSetting.matchIndex;

        const identifier = signalSetting.id + '-' + signalSetting.matchIndex;
        const usage = usageCount[identifier] ?? 1;
        seriesIds.push(chartSignalId);

        series.push({
          name,
          // TODO: Need to improve data format structure
          // @ts-ignore-next-line
          data: values,
          showInLegend: true,
          chartSignalId,
          yAxis: unit,
          color:
            shadeColor(signalSetting?.color, usage) ??
            getAlternativeColor(series.length),
          tooltip: {
            pointFormatter: createPointFormatter(unit, signalSetting.rounding),
            valueDecimals: numDecimalsForUnit(unit),
            // Don't show unit if its null
            valueSuffix: unit ? ' ' + unit : ''
          }
        });

        usageCount[identifier] = usage + 1;
      }
    }

    return {
      // Replace full time format string with the compact version.
      categories: sortedCategories.map((item) => categories[item]),
      series,
      seriesIds,
      yAxis
    };
  }, [
    signalInfo?.timeRange,
    signalInfo.signals,
    signalInfo.signalProviders,
    signalInfo.signalIdToProviderId,
    signalValues,
    signalTypesMap,
    signalUnitTypesMap,
    minMaxSettings,
    samplingInterval,
    getSignalSeriesName
  ]);

  // Reload data, smallest interval 15 minutes
  useInterval(onReload, 1000 * 60 * Math.max(refreshInterval, 15));

  const config: Highcharts.Options = useMemo(
    () => ({
      boost: {
        enabled: false
      },
      rangeSelector: {
        enabled: false
      },
      navigator: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      scrollbar: {
        liveRedraw: false,
        enabled: false
      },
      chart: {
        type: 'column',
        zoomType: false
      },
      xAxis: {
        labels: {
          formatter: ({ value }) => {
            return dataConfig.categories[
              _.isNumber(value) ? value : _.parseInt(value)
            ];
          },
          step: xAxisStep
        }
      },
      tooltip: {
        pointFormat: '{series.name}: {point.y}'
      },
      legend: {
        enabled: !hideLegend,
        symbolHeight: 12,
        symbolWidth: 12,
        symbolRadius: 0,
        squareSymbol: false
      },
      plotOptions: {
        series: {
          animation: {
            duration: parseFloat(animations.defaultSpeed) * 1000
          }
        },
        column: {
          stacking:
            stacking === ChartStackingOption.DISABLED
              ? undefined
              : (stacking as Highcharts.OptionsStackingValue),
          dataLabels: {
            enabled: false
          }
        }
      },
      series: dataConfig.series,
      yAxis: dataConfig.yAxis
    }),
    [
      xAxisStep,
      hideLegend,
      stacking,
      dataConfig.series,
      dataConfig.yAxis,
      dataConfig.categories
    ]
  );

  return (
    <ErrorBoundary>
      <StockChart
        seriesIds={dataConfig.seriesIds}
        config={config}
        hasError={hasError}
        isLoading={isLoading}
        hasPointsOverflow={hasPointsOverflow}
        containerWidth={Math.floor(size.width)}
      />
    </ErrorBoundary>
  );
};

type BarChartPanelProps = CustomPanelProps & {
  data: {
    refreshInterval: number;
    stacking: ChartStackingOption;
    hideLegend: boolean;
    values: SignalValuesDataSourceResult;
    xAxisStep?: number;
  };
};

const BarChartPanel = ({ data, panelApi }: BarChartPanelProps) => {
  const { values, ...other } = data;
  return (
    <BarChartPanelContent
      {...values}
      {...other}
      onReload={panelApi.reload}
      size={panelApi.size}
    />
  );
};

const sections: ModelFormSectionType<BarChartPanelConfig>[] = [
  {
    label: T.admin.dashboards.sections.barchart,
    initiallyCollapsed: true,
    lines: [
      {
        models: [
          {
            key: (input) => input.refreshInterval,
            modelType: ModelType.NUMBER,
            label: T.admin.dashboards.panels.types.linechart.refreshinterval,
            placeholder: _.toString(DEFAULT_REFRESH_INTERVAL),
            hasError: (value) => value < MIN_REFRESH_INTERVAL
          },
          {
            key: (input) => input.stacking,
            modelType: ModelType.OPTIONS,
            label: T.admin.dashboards.panels.types.barchart.stacking,
            placeholder: ChartStackingOptionsText[DEFAULT_STACKING_OPTION],
            options: _.map(ChartStackingOptionsText, (label, value) => ({
              label,
              value
            }))
          },
          {
            key: (input) => input.hideLegend,
            modelType: ModelType.BOOL,
            label: T.admin.dashboards.panels.types.linechart.hidelegend,
            isHorizontal: false
          }
        ]
      }
    ]
  },
  graphMinMaxSection
];

export const BarChartPanelData = {
  dataType: PanelDataType.CHART,
  helpPath: HelpPaths.docs.dashboard.dashboards.barchart,
  migrations: [
    {
      version: 2,
      migration: (
        panel: DashboardPanel,
        enums: GetEnumsAndFixedConfigurationsResponseModel,
        signalTypesMap: Record<string, SignalTypeResponseModel>
      ) => {
        panel.targets.values = migrateSignalSettingSystemNamesToSignalTypes(
          panel.targets.values,
          enums,
          signalTypesMap
        );
      }
    }
  ],
  sections,
  dataSourceSectionsConfig: {
    [DataSourceTypes.SIGNALS]: {
      optionalSignalModels: getSignalAggregationModels(true)
    }
  },
  emptyTargets: {
    values: {
      sourceType: DataSourceTypes.SIGNALS
    }
  }
};

export default React.memo(BarChartPanel);
